<template>
  <div class="container">
  <div class="input">
      <span v-for="option in options" :key="option">
        <div>{{option}}</div>
        <input type="checkbox" :value="selected.includes(option)" @click="selectOption(option)">
      </span>
  </div>
  </div>
</template>

<script>
export default {
  name:"SetClientColumns",
  data(){
    return {
      options: [],
      selected: [],
    }
  },
  mounted() {
    this.options = [
      'ФИО', 'Person Id', 'Пасспорт', 'Doc Issuer', 'Улица', 'Статус синхронизации', 'Соато', 'Регион', 'Статус проверки'
    ];
    this.selected = [
      'ФИО', 'Person Id', 'Пасспорт', 'Doc Issuer', 'Улица',
    ]
  },
  methods: {
    selectOption(option){
      if (this.selected.includes(option)){
        this.selected = this.selected.filter(select => {
          return select != 'option';
        })
      }else {
        this.selected.push(option);
      }
    }
  }
}
</script>

<style scoped>
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #d3d3d3;
  padding: 4rem 4rem;
  border-radius: 10px;
  background: #3C3f44;
  box-shadow: 5px 5px 5px 0px #343d4e;
}
</style>