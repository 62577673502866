<template>
  <div v-if="error">
    <h5>{{ error }}</h5>
  </div>
  <div v-else class="">
    <a-typography-title>Список Брокеров</a-typography-title>
    <a-row :gutter="[16,16]" justify="start">
      <a-col :span="4">
        <label class="search-bar-label">Название брокера</label>
        <a-input
            v-model:value="name"
            placeholder="name"
        />
      </a-col>
      <a-col :span="4">
        <label class="search-bar-label">Номер Брокера</label>
        <a-input
            v-model:value="mbco_no"
            placeholder="Номер"
        />
      </a-col>
      <a-col :span="4" class="button">
        <label class="search-bar-label"></label>
        <a-button style="margin-top: 22px;" type="primary" @click="createBroker">Добавить</a-button>
      </a-col>
    </a-row>
    <a-divider class="total-data" orientation="right" dashed>
      <a-col :span="4" class="total-data">
        <span class="total">Всего брокеров: {{ total }}</span>
        На этой странице:
        <a-select v-model:value="limit">
          <a-select-option value="10">10</a-select-option>
          <a-select-option value="20">20</a-select-option>
          <a-select-option value="50">50</a-select-option>
          <a-select-option value="100">100</a-select-option>
        </a-select>
      </a-col>
    </a-divider>
    <div class="table-responsive mt-30">
        <table class="table">
          <thead>
            <tr>
              <th>Broker Id</th>
              <th>Название Брокера</th>
              <th>Номер Брокера</th>
              <th>Логин Брокера</th>
              <th>Изменить</th>
            </tr>
          </thead>
          <tbody v-for="broker in brokers" :key="broker.id">
            <the-broker :broker="broker"></the-broker>
          </tbody>
        </table>
      </div>
    <div class="d-flex">
      <a-button v-if="page != 1" @click="prev" type="primary">Предыдущая страница </a-button>
      <a-button class="ml-auto" v-if="page != total_pages && total_pages != 0" @click="next" type="primary">Следующая страница</a-button>
    </div>
  </div>
</template>

<script>
import TheBroker from "@/components/admin-panel/single/TheBroker";
import { debounce }  from "debounce";
import {api} from "@/http";

export default {
  nameInput: "Broker",
  components: { TheBroker },
  data() {
    return {
      brokers: [],
      error: null,
      mbco_no: "",
      name: "",
      total: 0,
      total_pages: 0,
      limit: 50,
      page: 1,
    };
  },
  async mounted() {
    if(localStorage.getItem('role') !== 'cd') this.$router.push('/');
    this.page = this.$route.query.page || 1;
    await this.getList();
  },
  watch: {
    async mbco_no() {
      const getFilters = debounce(this.getList, 500);
      await getFilters();
    },
    async name() {
      const getFilters = debounce(this.getList, 500);
      await getFilters();
    },
    async page(value) {
      await this.getList();
      if (value > this.total_pages) this.page = this.total_pages;
      if (value < 1) this.page = 1;
      if (isNaN(parseInt(value))) this.page = 1;
    },
    async limit(){
      await this.getList();
    }
  },
  methods: {
    async getList() {
      try {
        const request = await api.get(
          `/broker?name=${this.name}&mbco_no=${this.mbco_no}&limit=${this.limit}&page=${this.page}`);
        if (request.status === 200) {
          this.brokers = request.data.brokers;
          this.total_pages = request.data.totalPages;
          this.total = request.data.total;
        }
      } catch (e) {
        this.error = "Broker not found";
      }
    },
    createBroker() {
      this.$router.push("/broker/create");
    },
    next() {
      this.page++;
      this.$router.push({ name: "broker", query: { page: this.page } });
    },
    prev() {
      this.page--;
      this.$router.push({ name: "broker", query: { page: this.page } });
    },
  },
};
</script>

<style>
.filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  background-color: #36393f;
  padding: 12px;
  border-radius: 5px;
}

.filter span{
  margin-right: 30px;
  margin-bottom: 10px;
}

.filter-row {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.filter-label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #b9bbbe;
}

.filter-input {
  background-color: #40444b;
  color: #dcddde;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

.create-button {
  background-color: #7289da;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  align-self: flex-end;
  font-weight: 500;
  margin-right: 10px;
  height: 35px;
  color: #dcddde;
  box-sizing: border-box;
  width: 110px;
  font-size: 17px;
}

.create-button:hover {
  background-color: #3c3c3c;
}

.button {
  right: 10%;
  position: absolute;
}
</style>
