<template>
  <div v-if="error">
    <h5>{{ error }}</h5>
  </div>
  <div v-else>
    <a-typography-title>Статистика</a-typography-title>
    <a-row :gutter="[16,16]" justify="start">
      <a-col :span="4" v-if="isCD">
        <label class="search-bar-label">Поиск по брокеру</label>
        <a-select
            ref="select"
            v-model:value="mbco_no"
            style="width: 100%"
        >
          <a-select-option value=""></a-select-option>
          <a-select-option v-for="broker in brokerList" :key="broker.mbco_no">{{ broker.name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
      <label class="search-bar-label">Номер партии</label>
      <a-select
          ref="select"
          v-model:value="section"
          style="width: 100%"
      >
        <a-select-option v-for="section in [1, 2, 3, 4]" :key="section">{{ section}}</a-select-option>
      </a-select>
      </a-col>
    </a-row>
        <table class="table mt-30">
          <thead>
            <tr>
              <th>Название Брокера</th>
              <th>Код Брокера</th>
              <th>Не проверенные</th>
              <th>Подтверждено соотвествие</th>
              <th>Подтверждено несоотвествие</th>
              <th>Ожидание актуализации</th>
              <th>Актуализированные</th>
              <th>Ошибка актуализации</th>
            </tr>
          </thead>
          <tbody v-for="stat in stats" :key="stat.name">
            <the-stat :stat="stat"></the-stat>
          </tbody>
        </table>
      </div>
</template>

<script>
import { api }      from "@/http";
import TheStat      from "@/components/admin-panel/single/TheStat";
import { debounce } from "debounce";
import { message }    from "ant-design-vue";

export default {
  nameInput: "Stats",
  components: { TheStat },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      stats: [],
      error: null,
      mbco_no: "",
      loading: false,
      brokerList: [],
      isCD: false,
      section: 4
    };
  },
  async mounted() {
    if(localStorage.getItem('role') == 'cd'){
      this.isCD = true;
    }
    await this.getList();
    await this.loadBrokers();
  },

  methods: {
    async getList() {
      if(!this.loading) {
        message.loading ('Loading..',0);
        this.loading = true;
      }
      try {
        const request = await api.get(`/client/stats?mbco_no=${this.mbco_no}&section=${this.section}`);
        this.stats = [];
        if (request.status === 200) {
          message.destroy();
          this.loading = false;
          for (let broker in request.data) {
            this.stats.push({ name: broker, ...request.data[broker] });
          }
        }
      } catch (e) {
        this.stats = [];
        this.error = "Stats not found";
      }
    },
    async loadBrokers() {
      this.brokerList = [];
      try {
        if (localStorage.getItem("role") === "cd") {
          const getBrokersRequest = await api.get("/broker?limit=1000");

          getBrokersRequest.data.brokers.forEach((broker) => {
            this.brokerList.push(broker);
          });
        }
      } catch (e) {
        console.log(e.response?.data);
      }
    },
  },
  watch: {
    async mbco_no() {
      if (localStorage.getItem("role") === "cd") {
        const getList = debounce(this.getList, 1000);
        getList();
      }
    },

    async section() {
      const getList = debounce(this.getList, 1000);
      getList();
    }
  },
};
</script>

<style>
</style>
