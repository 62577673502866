<template>
  <tr>
    <td>
      {{ broker.id }}
    </td>
    <td>
      {{ broker.name }}
    </td>
    <td>
      {{ broker.mbco_no }}
    </td>
    <td>
      {{ broker.cd_login }}
    </td>
    <td>
      <a-button @click="updateBroker" type="primary">Редактировать</a-button>
    </td>
  </tr>
</template>

<script>
export default {
  nameInput: "TheBroker",
  props: ["broker"],
  methods: {
    updateBroker(){
      this.$router.push('/broker/' + this.broker.id);
    }
  }
};
</script>

<style scoped>
.error {
  color: #C74747;
}
a:hover {
  background-color: #3c3c3c;
}
</style>
