<template>
  <tr>
    <td v-if="stat.name !== 'total'">{{stat.name}}</td>
    <td v-else>Итого</td>
    <td>{{stat.code}}</td>
    <td>{{stat.newClients}}</td>
    <td>{{stat.approvedClients}}</td>
    <td>{{stat.rejectedClients}}</td>
    <td>{{stat.notSyncedClients}}</td>
    <td>{{stat.syncedClients}}</td>
    <td>{{stat.errorSyncedClients}}</td>
  </tr>
</template>

<script>
export default {
  nameInput: "TheStat",
  props: ["stat",]
};
</script>

<style scoped>
</style>

