<template>
  <div class="">
    <a-typography-title>{{ buttonText }} Брокер</a-typography-title>
    <div v-if="error">
      <h3 class="error">{{ error }}</h3>
    </div>
    <div v-if="success">
      <h3 class="success">{{ success }}</h3>
    </div>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Код Брокера">
        <a-input v-model:value="mbco_no" />
      </a-form-item>

      <a-form-item label="Наименование Брокера">
        <a-input v-model:value="nameInput" />
      </a-form-item>
      <a-form-item label="Логин от fondbozori">
        <a-input v-model:value="login" />
      </a-form-item>
      <a-form-item label="Пароль от fondbozori">
        <a-input type="password" v-model:value="pass" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click.prevent="create">{{ buttonText }}</a-button>
        <a-button style="margin-left: 10px" @click="back">Отмена</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {api} from "@/http";

export default {
  name: "CreateBroker",
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      error: null,
      nameInput: "",
      mbco_no: "",
      login: "",
      pass: "",
      buttonText: "",
      success: null,
    };
  },
  methods: {
    back() {
      this.$router.push("/broker");
    },
    async create() {
      try {
        if (this.buttonText === "Изменить") {
          await this.update();
        } else {
          const request = await api.post(
            "/broker",
            {
              name: this.nameInput,
              cd_login: this.login,
              cd_pass: this.pass,
              mbco_no: this.mbco_no,
            },
          );

          if (request.status === 201) {
            this.error = null;
            this.success = "Вы создали новый брокер";
            this.buttonText = "Изменить";

            this.pass = "";
            this.login = "";

            this.$router.push("/broker/" + request.data.id);
          }
        }
      } catch (e) {
        this.error = e.response?.data?.error?.message;
        this.success = null;
      }
    },

    async update() {
      try {
        const data = {};

        if (this.login.length) data.cd_login = this.login;
        if (this.pass.length) data.cd_pass = this.pass;
        if (this.nameInput.length) data.name = this.nameInput;
        if (this.mbco_no.length) data.mbco_no = this.mbco_no;

        const request = await api.put(
          "/broker/" + this.$route.params.id,
          data,
        );

        if (request.status === 200) {
          this.error = null;
          this.success = "Вы успешно изменили брокер";
          this.buttonText = "Изменить";

          this.pass = "";
          this.$router.push("/broker/" + request.data.id);
        }
      } catch (e) {
        this.error = e.response?.data?.error?.message;
        this.success = null;
      }
    },
  },

  async mounted() {
    if(localStorage.getItem('role') !== 'cd') this.$router.push('/');
    if (this.$route.params.id) {
      this.buttonText = "Изменить";
      const request = await api.get(
        "/broker/" + this.$route.params.id,
      );

      if (request.status === 200) {
        this.nameInput = request.data?.name;
        this.mbco_no = request.data?.mbco_no;
        this.login = request.data?.cd_login;
      }
    } else {
      this.buttonText = "Добавить";
    }
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #d3d3d3;
  padding: 4rem 4rem;
  border-radius: 10px;
  background: #3C3f44;
  box-shadow: 5px 5px 5px 0px #343d4e;
}

.chatgpt-form {
  background-color: #3C3f44;
  color: #f1f1f1;
  border-radius: 0.5rem;
}


label {
  display: block;
  margin-bottom: 0.5rem;
}

.btn-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.button.primary {
  background-color: #0078d4;
  color: #f1f1f1;
}

.button.secondary {
  background-color: #B74747;
  color: #f1f1f1;
}

.button:hover {
  background-color: #3c3c3c;
}

.error {
  color: #C74747;
}

.success {
  color: #5dae8b;
}

h3{
  color: #eeeeee;
}
</style>
