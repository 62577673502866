import axios from "axios";
import dotenv       from "dotenv";
dotenv.config({ path: ".env" });

let url;

if (process.env.NODE_ENV == 'development'){
    url = 'https://cd-cabinet.jett.uz/api/'
}
else if (process.env.NODE_ENV == 'local'){
    url = 'http://localhost:3000';
}

export let api;
try {
    api = axios.create ({
        baseURL: url || '/api',
        headers:{
            'x-access-token':localStorage.getItem ('token')
        },
    });
}
catch (e) {
    api = axios.create ({
        baseURL: url || '/api',
        headers:{
            'x-access-token':localStorage.getItem ('token')
        },
    });
}
