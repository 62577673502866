<template>
  <div v-if="error">
    <h5>{{ error }}</h5>
  </div>
  <div v-else>
    <a-typography-title>Список Пользователей</a-typography-title>
    <a-row :gutter="[16,16]" justify="start">
      <a-col :span="4">
        <label class="search-bar-label">Название брокера</label>
        <a-input
            v-model:value="name"
            placeholder="name"
        />
      </a-col>
      <a-col :span="4">
        <label class="search-bar-label">Номер Брокера</label>
        <a-input
            v-model:value="mbco_no"
            placeholder="Номер"
        />
      </a-col>
      <a-col :span="4">
        <label class="search-bar-label">Роль пользователя</label>
        <a-select
            ref="select"
            v-model:value="role"
            style="width: 100%"
        >
          <a-select-option value="">Все</a-select-option>
          <a-select-option value="issuer">Эмитент</a-select-option>
          <a-select-option value="broker">Брокер</a-select-option>
          <a-select-option value="cd">ЦД</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4" class="button">
        <label class="search-bar-label"></label>
        <a-button style="margin-top: 22px;" type="primary" @click="createUser" >Добавить</a-button>
      </a-col>
    </a-row>
    <div class="wrap mt-30">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>User Id</th>
              <th>Название Брокера</th>
              <th>Номер Брокера</th>
              <th>ФИО Пользователя</th>
              <th>Логин Пользователя</th>
              <th>Изменить</th>
            </tr>
          </thead>
          <tbody v-for="user in users" :key="user.id">
            <the-user :user="user"></the-user>
          </tbody>
        </table>
      </div>
      <div class="d-flex">
        <a-button v-if="page != 1" @click="prev" type="primary">Предыдущая страница </a-button>
        <a-button class="ml-auto" v-if="page != total_pages && total_pages != 0" @click="next" type="primary">Следующая страница</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import TheUser from "@/components/admin-panel/single/TheUser";
import { debounce }  from "debounce";
import {api} from "@/http";

export default {
  name: "Users",
  components: { TheUser },
  data() {
    return {
      users: [],
      error: null,
      mbco_no: "",
      name: "",
      role: "",
      total: 0,
      total_pages: 0,
      limit: 10,
      page: 1,
    };
  },
  async mounted() {
    if (localStorage.getItem("role") !== "cd") this.$router.push("/");
    this.page = this.$route.query.page || 1;
    await this.getList();
  },
  watch: {
    async mbco_no() {
      const getFilters = debounce(this.getList, 500);
      await getFilters();
    },
    async name() {
      const getFilters = debounce(this.getList, 500);
      await getFilters();
    },
    async page(value) {
      await this.getList();
      if (value > this.total_pages) this.page = this.total_pages;
      if (value < 1) this.page = 1;
      if (isNaN(parseInt(value))) this.page = 1;
    },
    async role() {
      await this.getList();
    },
  },
  methods: {
    async getList() {
      try {
        const request = await api.get(
          `/admin/user?name=${this.name}&mbco_no=${this.mbco_no}&role=${this.role}&limit=${this.limit}&page=${this.page}`);
        if (request.status === 200) {
          this.users = request.data.users;
          this.total_pages = request.data.totalPages;
          this.total = request.data.total;
        }
      } catch (e) {
        this.error = "Users not found";
      }
    },
    next() {
      this.page++;
      this.$router.push({ name: "user", query: { page: this.page } });
    },
    prev() {
      this.page--;
      this.$router.push({ name: "user", query: { page: this.page } });
    },
    createUser() {
      this.$router.push("/user/create");
    },
  },
};
</script>

<style scoped>

.filter span {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
  margin-right: 15px;
}

.filter label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #b9bbbe;
}

.filter input,
.filter select {
  text-align: center;
  background-color: #40444b;
  color: #dcddde;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
}

.filter .create {
  display: flex;
  align-items: center;
}

.filter button.create {
  background-color: #7289da;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  align-self: flex-end;
  font-weight: 500;
  margin-right: 10px;
  height: 35px;
  color: #dcddde;
  box-sizing: border-box;
  width: 110px;
  font-size: 17px;
  justify-content: center;
}

.filter button.create:hover {
  background-color: #3c3c3c;
}


@media screen and (min-width: 768px) {
  .filter span {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.button {
  right: 10%;
  position: absolute;
}
</style>
