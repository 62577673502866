<template>
  <a-modal
    v-if="approveAllModal && showApproveAllButton"
    visible
    centered
    cancel-text="Отмена"
    ok-text="Подтвердить"
    @cancel="deselectApproveAll"
    @ok="approveAll"
  >
    Вы точно хотите подтвердить всех {{total}} пользователей?
  </a-modal>
  <div v-if="error">
    <h5>{{ error }}</h5>
  </div>
  <div v-else class="">
    <a-typography-title>Список Клиентов</a-typography-title>
    <a-row :gutter="[16, 16]" justify="start">
      <a-col :span="3">
        <label class="search-bar-label">Поиск по КЗЛ</label>
        <a-input v-model:value="crp" placeholder="Номер" />
      </a-col>
      <a-col :span="3">
        <label class="search-bar-label">Поиск по номеру документа</label>
        <a-input v-model:value="doc_num" placeholder="Номер" />
      </a-col>
      <a-col :span="3">
        <label class="search-bar-label">Поиск по id</label>
        <a-input v-model:value="pinfl" placeholder="Номер" />
      </a-col>
      <a-col :span="3">
        <label class="search-bar-label">Поиск по брокеру</label>
        <a-select
          ref="select"
          v-model:value="selectedBroker"
          style="width: 100%"
        >
          <a-select-option value=""></a-select-option>
          <a-select-option v-for="broker in brokerList" :key="broker.id">{{
            broker.name
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <label class="search-bar-label">Процент совпадения</label>
        <a-select
          ref="select"
          v-model:value="comparison_type"
          style="width: 100%"
        >
          <a-select-option value=""></a-select-option>
          <a-select-option value="max">&lt;=</a-select-option>
          <a-select-option value="min">&gt;=</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="2">
        <label class="search-bar-label">Фильтр по дате</label>
        <a-select ref="select" v-model:value="date_type" style="width: 100%">
          <a-select-option value=""></a-select-option>
          <a-select-option value="check">Дата проверки</a-select-option>
          <a-select-option value="sync">Дата синхронизации</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="2">
        <label class="search-bar-label">Номер партии</label>
        <a-select
            ref="select"
            v-model:value="section"
            style="width: 100%"
        >
          <a-select-option v-for="section in [1, 2, 3, 4]" :key="section">{{ section}}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="2">
        <div style="margin-top: 32px">
          <a-button block type="primary" @click="getList">Фильтр</a-button>
        </div>
      </a-col>
      <a-col :span="3">
        <div style="margin-top: 32px">
          <a-button block @click="clearFilter">Очистить фильтр</a-button>
        </div>
      </a-col>
    </a-row>
    <a-row
      v-if="date_type || comparison_type"
      class="mt-20"
      :gutter="[16, 16]"
      justify="start"
    >
      <a-col :span="4" v-if="comparison_type">
        <label class="search-bar-label"> % </label>
        <a-input
          v-if="comparison_type"
          class="search-bar-input"
          v-model:value="comparison_prcnt"
          placeholder="Процент"
        />
      </a-col>
      <a-col :span="4" v-if="date_type">
        <label class="search-bar-label">от</label>
        <a-date-picker
          style="width: 100%"
          @change="onChangeDateFrom"
          format="YYYY-MM-DD"
          v-model:value="date_from"
        />
      </a-col>
      <a-col :span="4" v-if="date_type">
        <label class="search-bar-label">до</label>
        <a-date-picker
          style="width: 100%"
          @change="onChangeDateTo"
          format="YYYY-MM-DD"
          v-model:value="date_to"
        />
      </a-col>
    </a-row>
    <div class="d-flex align-items-center mt-30 mb-30">
      <a-checkbox @click="setStatuses" v-model:value="isChecked" name="type"
        >Выбрать все</a-checkbox
      >
      <div class="ml-30">
        <a-button type="primary" @click="sync">Изменить статус</a-button>
      </div>
      <div class="ml-30" v-if="showApproveAllButton">
        <a-button type="primary" @click="selectApproveAll"
          >Подтвердить всех пользователей</a-button
        >
      </div>
    </div>
    <div>
      <div>
        <!--        <div class="filter-select">-->
        <!--          <span @click="$router.push('/client/options')">-->
        <!--            <button class="button-cmp">Выбрать колонки</button>-->
        <!--          </span>-->
        <!--        </div>-->
        <a-divider orientation="right" dashed>
          <a-col :span="4" class="total-data">
            <span class="total">Всего пользователей: {{ total }}</span>
            На этой странице:
            <a-select v-model:value="limit">
              <a-select-option value="10">10</a-select-option>
              <a-select-option value="20">20</a-select-option>
              <a-select-option value="50">50</a-select-option>
              <a-select-option value="100">100</a-select-option>
            </a-select>
          </a-col>
        </a-divider>
        <table class="statuses table" id="statuses">
          <thead>
            <tr class="statuses">
              <th
                v-for="(filter, index) in filters"
                :key="index"
                :class="[selected === index ? 'active' : '']"
                @click="select(index)"
              >
                {{ filter }}
              </th>
            </tr>
          </thead>
        </table>
        <div v-if="isLoading" class="lds-dual-ring"></div>
        <table class="table" id="table">
          <thead>
            <tr>
              <th>Выбрать</th>
              <th>Статус</th>
              <th>Брокер</th>
              <th>КЗЛ</th>
              <!--              <th>Статус проверки</th>-->
              <!--              <th>Sync Статус</th>-->
              <th>ФИО</th>
              <th>Пинфл / ИНН</th>
              <th>Паспорт</th>
              <th>Паспорт выдано</th>
              <th>Адрес</th>
              <th>Совпадение</th>
            </tr>
          </thead>
          <tbody v-for="(client, index) in clients" :key="client.id">
            <the-client
              @status-update="updateStatus"
              :client="client"
              :status="statuses[index]"
              :index="index"
            ></the-client>
          </tbody>
        </table>
      </div>
      <div class="d-flex">
        <a-button v-if="page != 1" @click="prev" type="primary"
          >Предыдущая страница
        </a-button>
        <a-button
          class="ml-auto"
          v-if="page != total_pages && total_pages != 0"
          @click="next"
          type="primary"
          >Следующая страница</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import TheClient from "@/components/admin-panel/single/TheClient";
import { debounce } from "debounce";
import { api } from "@/http";

export default {
  nameInput: "Client",
  components: { TheClient },
  data() {
    return {
      clients: [],
      error: null,
      syncStatus: "new",
      checkStatus: "new",
      isLoading: false,
      crp: "",
      doc_num: "",
      pinfl: "",
      total: 0,
      total_pages: 0,
      limit: 100,
      page: 1,
      isChecked: true,
      statuses: [],
      filters: [
        "Не проверенные",
        "Подтверждено соотвествие",
        "Подтверждено несоотвествие",
        "Ожидание актуализации",
        "Актуализированные",
        "Ошибка актуализации",
      ],
      selected: 0,
      brokerList: [],
      selectedBroker: "",
      broker_id: "",
      broker: "",
      comparison_type: "",
      comparison_prcnt: "",
      date_to: "",
      date_from: "",
      date_type: "",
      approveAllModal: false,
      showApproveAllButton: false,
      section: 4
    };
  },
  async mounted() {
    if (!localStorage.getItem("token")) this.$router.push("/login");
    if (
      api.defaults.headers["x-access-token"] != localStorage.getItem("token")
    ) {
      this.$router.go();
    }

    if (localStorage.getItem("role") === "broker") {
      this.showApproveAllButton = true;
    }

    this.page = parseInt(this.$route.query.page) || 1;
    this.selected = parseInt(this.$route.query.selected) || 0;
    this.limit = parseInt(this.$route.query.limit) || 100;
    if (!this.$route.query.page || this.page === 1) {
      await this.getList();
    }
    this.setStatuses();
    await this.loadBrokers();
  },

  watch: {
    async page(value) {
      document
        .getElementById("statuses")
        .scrollIntoView({ behavior: "smooth" });
      await this.getList();

      if (value > this.total_pages && this.total_pages !== 0) {
        this.page = this.total_pages;
      }
      if (value > this.total_pages && this.total_pages === 0) this.page = 1;
      if (value < 1) this.page = 1;
      if (isNaN(parseInt(value))) this.page = 1;

      this.$router.push({
        name: "client",
        query: { page: this.page, selected: this.selected, limit: this.limit },
      });
      this.isChecked = true;
      this.setStatuses();
    },
    async selectedBroker(value) {
      const broker = this.brokerList.find((element) => {
        return element.id === value;
      });
      if (broker) this.broker_id = broker.mbco_no;
      else this.broker_id = "";
      await this.loadBrokers();
    },
    async selected(value) {
      switch (value) {
        case 0:
          this.checkStatus = "new";
          this.syncStatus = "";
          break;
        case 1:
          this.checkStatus = "approved";
          this.syncStatus = "";
          break;
        case 2:
          this.checkStatus = "rejected";
          this.syncStatus = "";
          break;
        case 3:
          this.checkStatus = "approved";
          this.syncStatus = "new";
          break;
        case 4:
          this.checkStatus = "approved";
          this.syncStatus = "success";
          break;
        case 5:
          this.checkStatus = "approved";
          this.syncStatus = "error";
          break;
      }
      if (this.$route.query.selected != this.selected) this.page = 1;
      this.$router.push({
        name: "client",
        query: { page: this.page, selected: this.selected, limit: this.limit },
      });
      const getFilters = debounce(this.getList, 500);
      await getFilters();
    },
    async limit() {
      this.$router.push({
        name: "client",
        query: { page: this.page, selected: this.selected, limit: this.limit },
      });
      const getFilters = debounce(this.getList, 500);
      await getFilters();
      this.statuses = [];
      const status = "";
      for (let i = 0; i < this.clients.length; i++) {
        this.statuses.push(status);
      }
    },
  },
  methods: {
    onChangeDateFrom(date, dateString) {
      this.date_from = dateString;
    },
    onChangeDateTo(date, dateString) {
      this.date_to = dateString;
    },
    async getList() {
      message.loading("Loading..", 0);
      this.isLoading = true;
      try {
        let comparisonStr = "";
        if (this.comparison_type === "min") {
          comparisonStr = `&comparison_min=${this.comparison_prcnt}`;
        }
        if (this.comparison_type === "max") {
          comparisonStr = `&comparison_max=${this.comparison_prcnt}`;
        }
        const request = await api.get(
          `/client?limit=${this.limit}&page=${this.page}&check_status=${this.checkStatus}&sync_status=${this.syncStatus}` +
            `&crp=${this.crp}&pinfl=${this.pinfl}&doc_num=${this.doc_num}&broker_id=${this.broker_id}` +
            `&date_type=${this.date_type}&date_to=${this.date_to}&date_from=${this.date_from}&section=${this.section}` +
            comparisonStr
        );
        if (request.status === 200) {
          message.destroy();
          this.clients = request.data.clients;
          this.broker = request.data.broker;
          this.total_pages = request.data.totalPages;
          this.total = request.data.total;
        }
      } catch (e) {
        this.$router.push("/login");
        this.error = "Clients not found";
      } finally {
        this.isLoading = false;
      }
    },
    async loadBrokers() {
      this.brokerList = [];
      try {
        if (localStorage.getItem("role") === "cd") {
          const getBrokersRequest = await api.get("/broker?limit=1000");

          getBrokersRequest.data.brokers.forEach((broker) => {
            this.brokerList.push(broker);
          });
        } else {
          this.brokerList.push(this.broker);
        }
      } catch (e) {
        console.log(e.response?.data);
      }
    },
    next() {
      this.page++;
      this.$router.push({
        name: "client",
        query: { page: this.page, selected: this.selected, limit: this.limit },
      });
    },
    prev() {
      this.page--;
      this.$router.push({
        name: "client",
        query: { page: this.page, selected: this.selected, limit: this.limit },
      });
    },

    setStatuses() {
      this.isChecked = !this.isChecked;
      this.statuses = [];
      const status = this.isChecked ? "approved" : "";
      for (let i = 0; i < this.clients.length; i++) {
        this.statuses.push(status);
      }
    },
    updateStatus(id, status) {
      this.statuses[id] = status;
    },
    async sync() {
      this.isLoading = true;
      this.isChecked = false;
      try {
        const data = [];

        for (let i = 0; i < this.clients.length; i++) {
          data.push({ id: this.clients[i].id, status: this.statuses[i] });
        }

        const request = await api.post("/client/sync", { clients: data });

        if (request.status === 200) {
          this.statuses = [];
          for (let i = 0; i < this.clients.length; i++) {
            this.statuses.push("");
          }
          await this.getList();
        }
      } catch (e) {
        this.error = e.response?.data?.error?.message;
      } finally {
        this.isLoading = false;
      }
    },
    select(num) {
      this.selected = num;
    },
    async clearFilter() {
      this.broker_id = "";
      this.broker = "";
      this.crp = "";
      this.comparison_type = "";
      this.date_type = "";
      this.doc_num = "";
      this.pinfl = "";
      this.selectedBroker = "";
      await this.getList();
    },
    selectApproveAll() {
      this.approveAllModal = true;
    },
    deselectApproveAll() {
      this.approveAllModal = false;
    },
    async approveAll() {
      try {
        this.approveAllModal = false;
        message.loading("Loading..", 0);
        await api.post('/client/sync-all');
        message.destroy();
        await this.getList();

      } catch (e) {
        this.approveAllModal = false;
      }
    },
  },
};
</script>

<style scoped>
.statuses th.active {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
}

.total {
  margin-right: 20px;
}

.clear {
  background-color: #c74747;
}
</style>
