<template>
  <div v-if="loaded === true">
    <a-typography-title>{{ buttonText }} Пользователя</a-typography-title>
    <div v-if="error">
      <h3 class="error">{{ error }}</h3>
    </div>
    <div v-if="success">
      <h3 class="success">{{ success }}</h3>
    </div>
    <div>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="Роль">
          <a-select v-model:value="role" placeholder="please select your zone">
            <a-select-option v-if="buttonText=='Добавить' || (buttonText == 'Изменить' && role == 'cd')" value="cd">ЦД</a-select-option>
            <a-select-option value="issuer">Эмитент</a-select-option>
            <a-select-option value="broker">Брокер</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Код Брокера" v-if="role === 'broker'">
          <a-select v-model:value="brokerInput" placeholder="">
            <a-select-option v-for="broker in brokerList" :key="broker"  :value="broker">{{ broker }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="role === 'broker'" label="Наименование Брокера">
          <a-input v-model:value="nameInput" />
        </a-form-item>

        <a-form-item label="ФИО Пользователя">
          <a-input v-model:value="fullName" />
        </a-form-item>
        <a-form-item label="Логин Пользователя">
          <a-input v-model:value="login"  />
        </a-form-item>
        <a-form-item label="Пароль Пользователя">
          <a-input type="password" v-model:value="pass" />
        </a-form-item>



        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click.prevent="create">{{ buttonText }}</a-button>
          <a-button style="margin-left: 10px" @click="back">Отмена</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import {api} from "@/http";

export default {
  name: "CreateUser",
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      error: null,
      nameInput: "",
      fullName: "",
      login: "",
      pass: "",
      brokerInput: "",
      buttonText: "",
      broker_id: 0,
      success: null,
      role: "",
      brokerList: [],
      loaded: false
    };
  },
  methods: {
    back() {
      this.$router.push("/user");
    },
    async create() {
      try {
        if (this.buttonText === "Изменить") {
          this.method = 'update'
          await this.update();
        } else {
          this.method = 'create'
          const request = await api.post("/admin/user", {
            full_name: this.fullName,
            login: this.login,
            pass: this.pass,
            role: this.role,
            broker_id: this.broker_id,
          });

          if (request.status === 201) {
            this.error = null;
            this.success = "Вы создали нового пользователя";
            this.buttonText = "Изменить";

            this.pass = "";
            this.login = "";

            this.$router.push("/user/" + request.data.id);
          }
        }
      } catch (e) {
        this.error = e.response?.data?.error?.message;
        this.success = null;
      }
    },

    async update() {
      try {
        const data = {};

        if (this.login.length) data.login = this.login;
        if (this.pass.length) data.pass = this.pass;
        if (this.role.length) data.role = this.role;
        if (this.broker_id) data.broker_id = this.broker_id;

        if(this.role === 'cd') data.role = undefined;
        const request = await api.put(
          "/admin/user/" + this.$route.params.id,
          data
        );

        if (request.status === 200) {
          this.error = null;
          this.success = "Вы успешно изменили пользователя";
          this.buttonText = "Изменить";

          this.pass = "";
          this.$router.push("/user/" + request.data.id);
        }
      } catch (e) {
        this.error = e.response?.data?.error?.message;
        this.success = null;
      }
    },
  },
  watch: {
    async brokerInput(value) {
      if (this.role != 'cd') {
        try {
          const request = await api.get ("/broker/mbco/" + value);

          if (request.status === 200) {
            this.broker_id = request.data?.id;
            this.nameInput = request.data?.name;
            this.error = null;
          }
        } catch (e) {
          this.error = e?.response?.data?.error?.message;
          this.broker_id = 0;
          this.nameInput = "";
        }
      }
    }
  },
  async mounted() {
    if(localStorage.getItem('role') !== 'cd') this.$router.push('/');
    this.loaded = false;
    if (this.$route.params.id) {
      this.buttonText = "Изменить";
      const request = await api.get("/admin/user/" + this.$route.params.id);

      if (request.status === 200) {
        this.nameInput = request.data?.broker?.name;
        this.brokerInput = request.data?.broker?.mbco_no;
        this.login = request.data?.login;
        this.fullName = request.data?.full_name;
        this.role = request.data?.role;
      }
    } else {
      this.buttonText = "Добавить";
    }
    const getBrokersRequest = await api.get("/broker?limit=1000");

    getBrokersRequest.data.brokers.forEach((broker) => {
      this.brokerList.push(broker.mbco_no);
    });
    this.loaded = true;
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #d3d3d3;
  padding: 4rem 4rem;
  border-radius: 10px;
  background: #3C3f44;
  box-shadow: 5px 5px 5px 0px #343d4e;
}

.input2, select, label, h3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: #eeeeee;
}

.input2 > label {
  text-align: start;
}

.input2 > input {
  margin-top: 2px;
  height: 30px !important;
  background-color: #5a5d63;
  color: #eeeeee;
  border: 1px solid #5a5d63;
  box-shadow: 0 1px 0 rgb(255 255 255 / 25%) inset;
  font-family: 'Whitney', sans-serif;
  border-radius: 4px;
}

.error {
  color: #C74747;
}

.success {
  color: #5dae8b;
}

select {
  background-color: #5a5d63;
  color: #eeeeee;
  border: 1px solid #5a5d63;
  font-size: 16px;
  padding: 3px;
  border-radius: 5px;
}

select option {
  background-color: #40444b;
  color: #eeeeee;
  text-align: center;
}

.btn-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.button.primary {
  background-color: #0078d4;
  color: #f1f1f1;
}

.button.secondary {
  background-color: #B74747;
  color: #f1f1f1;
}

.button:hover {
  background-color: #3c3c3c;
}

</style>
