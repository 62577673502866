import { createApp } from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { createWebHistory, createRouter } from "vue-router";

import App from "./App.vue";
import Login from "./components/Login";
import Brokers from "@/components/admin-panel/list/Brokers";
import CreateBroker from "@/components/admin-panel/single/CreateBroker";
import Users from "@/components/admin-panel/list/Users";
import CreateUser from "@/components/admin-panel/single/CreateUser";
// import Header           from "@/components/Header";
import Clients from "@/components/admin-panel/list/Clients";
import Stats from "@/components/admin-panel/list/Stats";
import SetClientColumns from "@/components/admin-panel/single/SetClientColumns";

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      component: Login,
      props: true,
    },
    {
      path: "/broker",
      component: Brokers,
      name: "broker",
      props: true,
    },
    {
      path: "/broker/create",
      component: CreateBroker,
      name: "broker-create",
      props: true,
    },
    {
      path: "/broker/:id",
      component: CreateBroker,
      name: "broker-update",
      props: true,
    },
    {
      path: "/user",
      component: Users,
      name: "user",
      props: true,
    },
    {
      path: "/user/create",
      component: CreateUser,
      name: "user-create",
      props: true,
    },
    {
      path: "/user/:id",
      component: CreateUser,
      name: "user-update",
      props: true,
    },
    {
      path: "/",
      component: Clients,
      name: "client",
      props: true,
    },
    {
      path: "/client/stats",
      component: Stats,
      name: "stats",
      props: true,
    },
    {
      path: "/client/options",
      component: SetClientColumns,
      name: "options",
      props: true,
    },
  ],
});

app.use(router);
app.use(Antd);
// app.component('the-header', Header);
app.mount("#app");
