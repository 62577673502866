<template>
  <tr class="current">
    <td></td>
    <td>Текущеее</td>
    <td>{{client.broker_name}}</td>
    <td>{{client.crp}}</td>
    <td>{{ client.cd_name }}</td>
    <td>{{ client.cd_person_id }}</td>
    <td>{{ client.cd_doc_num }}</td>
    <td>{{ client.cd_doc_issuer }}</td>
    <td></td>
    <td></td>
  </tr>
  <tr class="new">
    <td>
      <a-select
          ref="select"
          v-model:value="currentStatus"
          style="width: 100%"
      >
        <a-select-option value="">Не выбран</a-select-option>
        <a-select-option value="new">Не проверен</a-select-option>
        <a-select-option value="approved">Соответствует</a-select-option>
        <a-select-option value="rejected">Не соответсвует</a-select-option>
      </a-select>
    </td>
    <td>Новое</td>
    <td></td>
    <td></td>
    <td>{{client.last_name}} {{client.first_name}} {{client.middle_name}}</td>
    <td>{{client.pinfl}} <br> {{client.tin}}</td>
    <td>{{client.doc_number}}</td>
    <td>{{client.address_district}}</td>
    <td>{{ client.address_text }}</td>
    <td>{{client.comparison}}</td>
  </tr>
</template>

<script>
export default {
  nameInput: "TheClient",
  props: ["client", "status", "index"],
  emits: ['status-update'],
  data() {
    return { currentStatus: "" };
  },
  watch: {
    status(){
      this.currentStatus = this.status;
    },
    currentStatus(){
      this.$emit('status-update', this.index, this.currentStatus)
    }
  },
  mounted() {
    this.currentStatus = this.status;
  }
};
</script>

<style scoped>
.error {
  color: #C74747;
}
</style>
