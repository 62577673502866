<template>
  <div style="width: 100%; height: 100%" class="d-flex justify-content-center align-items-center">
  <div style="width: 600px;">
    <a-typography-title>Авторизация</a-typography-title>
    <div v-if="error">
      <h3 class="error">{{ error }}</h3>
    </div>
  <a-form
      @submit.prevent="login"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
  >
    <a-form-item
        label="Логин"
        name="username"
    >
      <a-input v-model:value="loginInput" />
    </a-form-item>

    <a-form-item
        label="Пароль"
        name="password"
    >
      <a-input-password v-model:value="passwordInput" />
    </a-form-item>

    <a-form-item :wrapper-col="{ span: 24 }">
      <a-button type="primary" html-type="submit">Войти</a-button>
    </a-form-item>
  </a-form>
  </div>
  </div>
</template>

<script>
import dotenv    from "dotenv";
import {api}     from "@/http";
import {message} from "ant-design-vue";
dotenv.config({ path: ".env" });

export default {
  name: 'Login',
  data() {
    return {
      loginInput: '',
      passwordInput: '',
      error: null,
    };
  },
  mounted() {
    message.destroy();
  },
  methods: {
    async login() {
      try {
        const request = await api.post ("/admin/login",{
          login:this.loginInput,
          pass:this.passwordInput
        });
        if (request.status === 200) {
          this.error = null;
          localStorage.setItem('token', request.data?.token?.access_token);
          localStorage.setItem('role', request.data?.user?.role);
          this.$router.push('/');
        }
      }
      catch (e) {
        this.error = e.response?.data?.error?.message;
      }
    },
  },
};
</script>

<style>
#login_button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 120px;
  color: #f1f1f1;
  margin-top: 10px;
  font-size: 20px;
}

#login_button:hover {
  background-color: #3c3c3c;
}

.error {
  color: #C74747;
}
</style>
