<template>
  <main>
    <a-layout class="layout">
      <a-layout-header v-if="$route.path !== '/login'">
        <div class="logo" />
        <a-menu
            v-model:selectedKeys="selectedKeys"
            theme="dark"
            mode="horizontal"
            :style="{ lineHeight: '64px' }"
        >
          <a-menu-item v-if="role==='cd'" key="broker"><router-link to="/broker">Брокеры</router-link></a-menu-item>
          <a-menu-item v-if="role==='cd'" key="users"><router-link to="/user">Пользователи</router-link></a-menu-item>
          <a-menu-item key="client"><router-link to="/">Клиенты</router-link></a-menu-item>
          <a-menu-item key="stats"><router-link to="/client/stats">Статистика</router-link></a-menu-item>
          <a-menu-item key="Login"><router-link to="/login">Выйти</router-link></a-menu-item>
        </a-menu>
      </a-layout-header>
      <a-layout-content style="padding: 0 50px; margin: 30px 0">
        <div :style="{ background: '#fff', padding: '24px', minHeight: '280px' }"><router-view></router-view></div>
      </a-layout-content>
    </a-layout>
  <div>
  </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      selectedKeys: [this.$route.name],
      role: '',
    };
  },
  mounted() {
    this.role = localStorage.getItem('role');
  },
  watch: {
    '$route.name': {
      handler: function() {
        // if (newVal === 'broker') {
        //   this.selectedKeys = '1'
        // } else if (newVal === 'user') {
        //   this.selectedKeys = '2'
        // } else if (newVal === '/') {
        //   this.selectedKeys = '3'
        // } else if (newVal === '/client/stats') {
        //   this.selectedKeys = '4'
        // }
      },
      immediate: true,
    },
  }
};
</script>

<style>
h1.ant-typography, .ant-typography h1 {
  font-size: 34px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ccc;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
}
.table tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}
.justify-content-between {
  -ms-flex-pack: justify!important;
  justify-content: space-between!important;
}
.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}
.justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center!important;
}
.align-items-start {
  -ms-flex-align: start!important;
  align-items: flex-start!important;
}
.align-items-end {
  -ms-flex-align: end!important;
  align-items: flex-end!important;
}
.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}
.ml-auto{
  margin-left: auto;
}
.mr-auto{
  margin-right: auto;
}
.m-auto{
  margin:0 auto;
}
.mt-30{
  margin-top: 30px;
}
.mt-20{
  margin-top: 20px;
}
.mb-30{
  margin-bottom: 30px;
}
.ml-30{
  margin-left: 30px;
}
.mr-30{
  margin-right: 30px;
}
.search-bar-label{
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
}
body{
  background: #f0f2f5;
}
.btn-group a {
  display: inline-block;
  background-color: #198fff;
  padding: 5px 10px;
  border-radius: 10%;
  color: #fff;
}
.ant-message {
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
  height: 100%;
}
.ant-message:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #0000008c;
  z-index: 1;
}
.ant-message-notice {
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
