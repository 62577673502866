<template>
  <tr>
    <td>
      {{ user.id }}
    </td>
    <td>
      {{ user?.broker?.name }}
    </td>
    <td>
      {{ user?.broker?.mbco_no }}
    </td>
    <td>
      {{ user.full_name }}
    </td>
    <td>
      {{ user.login }}
    </td>
    <td>
      <a-button @click="updateUser" type="primary">Редактировать</a-button>
    </td>
  </tr>
</template>

<script>
export default {
  nameInput: "TheUser",
  props: ["user"],
  methods: {
    updateUser(){
      this.$router.push('/user/' + this.user.id);
    }
  }
};
</script>

<style scoped>
.error {
  color: #C74747;
}

a:hover {
  background-color: #3c3c3c;
}

</style>
